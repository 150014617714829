import { FC } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { styled } from 'styled-components';

import 'react-multi-carousel/lib/styles.css';
import { OFICINA } from '../../config/constants';
const Title = styled.h1`
  font-size: 65px;
  font-weight: 600;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: 0px;
  word-spacing: 0em;
  color: #000;
  font-family: 'Gotham Medium';
`;
const click = (id: string) => {
  const element = document.getElementById(id);
  element?.scrollIntoView({
    behavior: 'smooth'
  });
};

const Intro: FC<{ isMobile: boolean; isTablet: boolean; isDesktop: boolean }> = ({ isMobile, isTablet, isDesktop }) => {
  return (
    <section id="intro" style={{ padding: '1em 0' }} className="bg-light">
      <Container className="mt-5 mb-5">
        <Row>
          <Col md={12} lg={6} sm={6} className={`fadeInUp ftco-animated ${isTablet ? 'sum-tablet' : ''}`}>
            <Title>Convertite en agente inmobiliario</Title>

            <div>
              <p style={{ color: '#324A6D;' }}>
                Desarrolla tu propio negocio inmobiliario en <strong>{`${OFICINA}.`}</strong>
              </p>{' '}
              <Button
                style={{ padding: '1em' }}
                variant="contained"
                color="primary"
                onClick={() => click('formulario')}
                className="background-azul"
                sx={{ mt: 2 }}
              >
                Ser parte de la familia {OFICINA}
              </Button>
            </div>
          </Col>

          {!isMobile && (
            <Col md={12} lg={6} sm={6} className="d-flex fadeInUp">
              <img style={{ width: '100%' }} src={`/img/contacto.jpeg`} alt="Agente" />
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
};
export default Intro;
