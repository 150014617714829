import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../stores/store';
import { setKey } from '../../stores/sharedSlice';

export const Menu = () => {
  const dispatch = useAppDispatch();
  const key = useAppSelector<string>(state => state.shared.key);
  const [isActive, setIsActive] = useState<boolean>(false);

  const showMenu = () => {
    // console.log(window.innerWidth);
    if (window.innerWidth >= 992) return;
    setIsActive(!isActive);
  };

  const click = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  const navigate = useNavigate();

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark ftco-navbar-light" id="ftco-navbar">
      <div className="container">
        <button
          onClick={showMenu}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#ftco-nav"
          aria-controls="ftco-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="oi oi-menu"></span> <MenuIcon /> Menu
        </button>
        <div className={`collapse navbar-collapse ${isActive ? 'show' : ''}`} id="ftco-nav">
          <ul className="navbar-nav ml-auto">
            {key !== 'Sumate' ? (
              <>
                <li className="nav-item active">
                  <button
                    onClick={() => {
                      dispatch(setKey('Sumate'));
                      navigate('/sumate');
                    }}
                    className="nav-link btn-link"
                  >
                    Sumate
                  </button>
                </li>
                <li className="nav-item active">
                  <button onClick={() => click('nosotros')} className="nav-link btn-link">
                    Nuestra Familia
                  </button>
                </li>
                <li className="nav-item active">
                  <button onClick={() => click('destacadas')} className="nav-link btn-link">
                    Destacadas
                  </button>
                </li>
                <li className="nav-item">
                  <button onClick={() => click('agentes')} className="nav-link btn-link">
                    Equipo Cuore
                  </button>
                </li>
                <li className="nav-item">
                  <button onClick={() => click('propiedades')} className="nav-link btn-link">
                    Propiedades
                  </button>
                </li>
                <li className="nav-item">
                  <button onClick={() => click('section-contacto')} className="nav-link btn-link">
                    Contanos que necesitas y te ayudamos...
                  </button>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item active">
                  <button
                    onClick={() => {
                      dispatch(setKey('Home'));
                      navigate('/');
                    }}
                    className="nav-link btn-link"
                  >
                    Home
                  </button>
                </li>
                <li className="nav-item active">
                  <button onClick={() => click('beneficios')} className="nav-link btn-link">
                    Beneficios
                  </button>
                </li>
                <li className="nav-item active">
                  <button onClick={() => click('intro')} className="nav-link btn-link">
                    Sumate
                  </button>
                </li>
                <li className="nav-item active">
                  <button onClick={() => click('opu')} className="nav-link btn-link">
                    Oportunidades
                  </button>
                </li>
                <li className="nav-item active">
                  <button onClick={() => click('formulario')} className="nav-link btn-link">
                    Ser parte de la familia...
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};
