import { motion, useScroll } from 'framer-motion';
import { FC, useEffect } from 'react';
import ScrollToTop from 'react-scroll-to-top';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

import Agentes from '../componentes/agentes.component';
import Contacto from '../componentes/contacto.component';
import Datos from '../componentes/datos.component';
import Destacadas from '../componentes/destacadas.component';
import Intro from '../componentes/intro.component';
import Propiedades from '../componentes/propiedades.component';
import { config } from '../config/config';
import { Agent } from '../models/Agent';
import { getAgents } from '../services/agents.services';
import { setAgents } from '../stores/agentsSlice';
import { useAppDispatch, useAppSelector } from '../stores/store';
import { setKey } from '../stores/sharedSlice';
import Nosotros from '../componentes/nosotros.component';

// helper para obtener parametros
// import './home.scss';
const Home: FC = () => {
  const dispatch = useAppDispatch();

  const remaxAgents = useAppSelector<Agent[]>(state => state.agents.data);
  useEffect(() => {
    dispatch(setKey('Home'));
  }, []);
  useEffect(() => {
    const agents = async () => {
      try {
        const remaxAgents = await getAgents();
        dispatch(setAgents(remaxAgents.items.filter(a => a.showInTeam)));
      } catch (error) {
        console.error(error);
      }
    };
    agents();
  }, []);

  const titulo = config.TITULO;
  const { scrollYProgress } = useScroll();
  return (
    <>
      <ScrollToTop smooth component={<ArrowCircleUpIcon />} style={{ right: '60px' }} />
      <Intro />
      <Nosotros />
      <Destacadas />
      <Agentes agentes={remaxAgents} />
      <Propiedades pages={5} />
      <Contacto />
      <Datos happies={154689} />
    </>
  );
};

export default Home;
