import Question, { Answer } from '../models/Question';
import axiosClient from './axiosClient';

export const getQuestions = async (): Promise<Question[]> => {
  try {
    const { data } = await axiosClient.get(`/jobquest`);
    return data.questions;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const sendQuestions = async (answer: { questions: Answer[] }): Promise<void> => {
  try {
    await axiosClient.post(`/postulation`, answer);
  } catch (error: any) {
    return Promise.reject(error);
  }
};
