import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ManagerQuestions, StateManager } from '../models/Question';

interface QuestionInfo {
  manager: ManagerQuestions | undefined;
  loading: boolean;
  state: StateManager;
}

const initialState: QuestionInfo = {
  manager: undefined,
  loading: true,
  state: 'INIT'
};

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setManager: (state, action: PayloadAction<ManagerQuestions>) => {
      state.manager = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setState: (state, action: PayloadAction<StateManager>) => {
      state.state = action.payload;
    }
  }
});

// Exporta las acciones para usarlas en los componentes
export const { setManager, setLoading, setState } = questionsSlice.actions;

// Exporta el reducer para usarlo en el store
export default questionsSlice.reducer;
