import react, { FC, useEffect } from 'react';
import { CircularProgress, Container } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import { ManagerQuestions } from '../../../models/Question';
import { Questions } from './Questions.component';
import { useAppDispatch, useAppSelector } from '../../../stores/store';
import { setState } from '../../../stores/questionsSlice';
import { sendQuestions } from '../../../services/questions.services';

const CardForm: FC<{ manager: ManagerQuestions | undefined }> = ({ manager }) => {
  const { state } = useAppSelector(state => state.questions);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (state === 'FINISH' && manager !== undefined) {
      const sendAnswerRequest = async () => {
        try {
          await sendQuestions(manager?.getAnswer());
          dispatch(setState(manager.nextState()));
        } catch (error) {
          console.error(error);
        }
      };
      sendAnswerRequest();
    }
  }, [state, manager]);

  return (
    <Card sx={{ minWidth: '85%' }} className="mb-5 mt-5 pb-5 pt-5">
      <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
        Sumate a nuestra familia
      </Typography>
      {manager === undefined && (
        <div className="mt-5 mb-5">
          <CircularProgress size="3rem" />
        </div>
      )}
      {manager !== undefined && state === 'INIT' && (
        <>
          <Typography variant="h5" component="div">
            ¿Listo para empezar?
          </Typography>
          <Typography variant="h6" component="div">
            Respondé unas preguntas y nos contactaremos
          </Typography>
          <Button
            style={{ padding: '1em' }}
            variant="contained"
            color="primary"
            onClick={() => dispatch(setState(manager.nextState()))}
            className="background-azul"
            sx={{ mt: 2 }}
          >
            Iniciar formulario
          </Button>
        </>
      )}
      {manager !== undefined && state === 'PROGRESS' && <Questions manager={manager} />}
      {manager !== undefined && state === 'FINISH' && (
        <div className="mt-5 mb-5">
          <CircularProgress size="3rem" />
          <p>Enviando formulario</p>
        </div>
      )}
      {manager !== undefined && state === 'RESET' && (
        <>
          <Typography variant="h5" component="div">
            Tus respuestas ya fueron enviadas
          </Typography>
          <Typography variant="h6" component="div">
            Las analizaremos y te contactaremos, gracias
          </Typography>
        </>
      )}
    </Card>
  );
};
export default CardForm;
