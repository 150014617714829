/* eslint-disable react/jsx-key */
import Bathtub from '@mui/icons-material/Bathtub';
import NightShelter from '@mui/icons-material/NightShelter';
import ShareIcon from '@mui/icons-material/Share';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import React, { FC } from 'react';
import { Col } from 'reactstrap';
import { Typography } from '@mui/material';

import { config } from '../../config/config';
import Property from '../../models/Property';
import { incCompartir } from '../../services/properties.services';
import { ReservadoDiv, ReservadoSpan } from '../../styled/intro';
import Contact from './contact.component';

const publicationUrlWithRedirect = (publicationId: string) => config.API_URL + '/properties/click/' + publicationId;

const Propiedad: FC<{ p: Property; key: number; isFeature: boolean }> = ({ p, key, isFeature }) => {
  const generateIcon = (
    f: string
  ): import('react').ReactElement<any, string | import('react').JSXElementConstructor<any>> | undefined => {
    if (f.includes('ambientes')) {
      return <TagFacesIcon />;
    }
    if (f.includes('baños')) {
      return <Bathtub />;
    }
    if (f.includes('habitacion') || f.includes('dormitorio')) {
      return <NightShelter />;
    }
    return <></>;
  };

  const armarMailContacto = (): string => {
    return `mailto:${p.contactEmail}?subject=${p.description}&body=Hola ${p.contact.name}, 
    te contacto por la siguiente propiedad: ${p.description} ${publicationUrlWithRedirect(p.publicationId)}`;
  };

  const mainPropery = (p: Property, isFeature: boolean) => {
    return (
      <div className="properties">
        {p.reserved && (
          <ReservadoDiv>
            <ReservadoSpan>RESERVADO</ReservadoSpan>
          </ReservadoDiv>
        )}
        <a
          href={publicationUrlWithRedirect(p.publicationId)}
          target="_blank"
          className="img img-2 prop d-flex justify-content-center align-items-center img-props"
          style={{ backgroundImage: `url(${p.image})`, position: 'initial' }}
          rel="noreferrer"
        >
          <div className="icon d-flex justify-content-center align-items-center">
            <span className="icon-search2"></span>
          </div>
        </a>

        <div className="text p-3">
          <div className="info">
            <span className="status sale">{p.type?.toUpperCase()}</span>
            <span className="status type">{p.propertyType?.toUpperCase()}</span>
          </div>
          <div>
            <div className="d-flex justify-content-between align-items-center" style={{ paddingTop: '10px' }}>
              <span className="price">{(p.currency ?? '') + ' ' + p.price}</span>
              <IconButton
                aria-label="compartir"
                onClick={async () => {
                  await incCompartir(p.publicationId);
                  window.open(
                    'https://api.whatsapp.com/send?text=' +
                      encodeURIComponent(
                        'Hola, mira la propiedad que encontre en REM/AX Sur III ' +
                          publicationUrlWithRedirect(p.publicationId)
                      ),
                    '_blank'
                  );
                }}
              >
                <ShareIcon />
              </IconButton>
            </div>
            <p>{p.expenses ?? ''}</p>
          </div>
          {/* <Divider /> */}
          <Box sx={{ p: 0 }} style={{ marginTop: '0.0em', padding: '0em 0 1em 0' }}>
            <Stack direction="row" spacing={{ xs: 1, sm: 2 }} useFlexGap flexWrap="wrap">
              {p.features.length > 0 &&
                p.features.map((f, _i) => (
                  <React.Fragment key={_i}>
                    {/* {generateIcon(f)} */}
                    <Typography>{f}</Typography>
                  </React.Fragment>
                ))}
            </Stack>
          </Box>
          {/* <Divider /> */}
          <div className="d-flex">
            <div className="one">
              <h3 className="property-description">
                <a href={publicationUrlWithRedirect(p.publicationId)} target="_blank" rel="noreferrer">
                  {p.description}
                </a>
              </h3>
            </div>
          </div>
          <p>{p.address + (p.locality ? ', ' + p.locality : '') + (p.city ? ', ' + p.city : '')}</p>
          {!isFeature && (
            <>
              <p className="agent-info" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <img className="avatar" src={p.contact.image} alt="" />
                <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                  <span
                    style={{
                      fontStyle: 'inherit',
                      marginLeft: '20px',
                      color: '#060202'
                    }}
                  >
                    Contacto
                  </span>
                  <span className="agent-name"> {p.contact.name}</span>
                </div>
              </p>
              <Divider />
              <Contact
                phone={p.contactPhone}
                textWhastapp={
                  p.contactPhone
                    ? `https://wa.me/${p.contactPhone}?text=Hola, te contacto por la siguiente publicación  
                        ${publicationUrlWithRedirect(p.publicationId)}`
                    : undefined
                }
                textEmail={p.contactEmail ? armarMailContacto() : undefined}
              />
            </>
          )}
        </div>
      </div>
    );
  };
  return isFeature ? (
    <div key={key} style={{ margin: '0 2em' }}>
      {mainPropery(p, isFeature)}
    </div>
  ) : (
    <Col md={6} lg={6} xl={4} className="fadeInUp ftco-animated" key={key}>
      {mainPropery(p, isFeature)}
    </Col>
  );
};
export default Propiedad;
