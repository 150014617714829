export interface Options {
  option: string;
  killer: boolean;
  freeOption: false;
}

export default interface Question {
  question: string;
  type: 'Open' | 'Closed';
  inputType: 'text' | 'phone' | 'email' | 'number' | 'select' | 'radio' | 'textarea';
  options?: Options[];
}

export interface Answer {
  question: string;
  answer: string;
}

export interface Response extends Answer {
  freeOption: boolean;
  freeOptionAnswer: string;
}

export type StateManager = 'INIT' | 'PROGRESS' | 'FINISH' | 'RESET';

export class ManagerQuestions {
  questions: Question[];
  responses: Response[];
  nextQuestion: number;
  state: StateManager;

  constructor(questions: Question[]) {
    this.questions = questions;
    this.responses = [];
    this.initResponses();
    this.nextQuestion = 0;
    this.state = 'INIT';
  }

  private initResponses(): void {
    this.questions.forEach(q => {
      this.responses.push({
        question: q.question,
        answer: '',
        freeOption: false,
        freeOptionAnswer: ''
      });
    });
  }

  getAnswer(): { questions: Answer[] } {
    return {
      questions: this.responses.map(r => {
        return {
          question: r.question,
          answer: r.freeOption ? r.freeOptionAnswer : r.answer
        };
      })
    };
  }

  getCurrent(): Question {
    return this.questions[this.nextQuestion];
  }

  getNext(): number {
    if (this.nextQuestion < this.questions.length - 1) {
      this.nextQuestion++;
    }
    return this.nextQuestion;
  }

  getPrev(): number {
    if (this.nextQuestion > 0) {
      this.nextQuestion--;
    }
    return this.nextQuestion;
  }

  getIndex(): number {
    return this.nextQuestion;
  }

  isFinish(): boolean {
    return this.nextQuestion === this.questions.length - 1;
  }

  nextState(): StateManager {
    switch (this.state) {
      case 'INIT':
        this.state = 'PROGRESS';
        break;
      case 'PROGRESS':
        this.state = 'FINISH';
        break;
      case 'FINISH':
        this.state = 'RESET';
    }
    return this.state;
  }
}
