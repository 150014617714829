import '@typeform/embed/build/css/widget.css';
import { FC, useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Container } from 'reactstrap';
import { styled } from 'styled-components';

import { OFICINA } from '../../config/constants';
import { ManagerQuestions } from '../../models/Question';
import { getQuestions } from '../../services/questions.services';
import { setLoading } from '../../stores/questionsSlice';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { azulRemax } from '../../styled/const';
import CardForm from '../subComponents/sumate/CardForm.component';
const Title = styled.h3`
  font-size: 48px;
  font-weight: 600;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: 0px;
  word-spacing: 0em;
  color: #fff;
  font-family: 'Gotham Medium';
  text-align: center;
  margin-bottom: 3px;
`;

const DescItems = styled.strong`
  color: #324a6d;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 300;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.5em;
  letter-spacing: 0px;
  word-spacing: 0em;
  color: #fff;
`;

const ContainerItems = styled(Container)`
  text-align: center;
`;

const Formulario: FC<{ isMobile: boolean; isTablet: boolean; isDesktop: boolean }> = ({
  isMobile,
  isTablet,
  isDesktop
}) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(state => state.questions);

  const [manager, setManager] = useState<ManagerQuestions | undefined>(undefined);

  useEffect(() => {
    const loadingManager = async () => {
      try {
        dispatch(setLoading(true));
        const questions = await getQuestions();
        setManager(new ManagerQuestions(questions));
        dispatch(setLoading(false));
      } catch (error) {
        console.error(error);
      }
    };

    loadingManager();
  }, []);

  return (
    <section id="formulario" style={{ padding: '1em 0', backgroundColor: azulRemax }}>
      <ContainerItems className="mt-5 mb-5">
        <Title>
          Unite a la familia <strong> {`${OFICINA}.`}</strong>
        </Title>
        <DescItems>
          Buscamos personas apasionadas, con o sin experiencia en el sector, que quieran nuevos desafíos. Completá el
          formulario con tus datos y nuestro equipo se pondrá en contacto para que juntos construyamos el próximo
          capítulo de tu carrera
        </DescItems>
        <CardForm manager={manager} />
      </ContainerItems>
    </section>
  );
};
export default Formulario;

// https://form.typeform.com/to/pHSRcW5X?typeform-embed-id=31278889168670343&typeform-embed=embed-widget&typeform-source=remax-premium.com.ar&typeform-medium=snippet&typeform-medium-version=next&typeform-embed-handles-redirect=1&typeform-embed-no-heading=true
