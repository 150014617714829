import { Box, Button, FormControl, FormLabel, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import Question, { ManagerQuestions, Options, Response } from '../../../models/Question';
import { azulRemax, rojoRemaxAlt } from '../../../styled/const';
import { useAppDispatch } from '../../../stores/store';
import { setState } from '../../../stores/questionsSlice';

export const Questions: FC<{ manager: ManagerQuestions }> = ({ manager }) => {
  const [question, setQuestion] = useState<Question>(manager.getCurrent());
  const [answer, setAnswer] = useState<Response[]>(manager.responses);
  const [index, setIndex] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setQuestion(manager.getCurrent());
  }, [index]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIndex(manager.getNext());
    dispatch(setState(manager.nextState()));
  };

  return (
    <Container>
      {error && (
        <Typography variant="h6" gutterBottom color={rojoRemaxAlt}>
          Por favor complete este campo
        </Typography>
      )}

      <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
        <FormControl fullWidth style={{ maxWidth: '400px' }}>
          <FormLabel htmlFor={manager.getIndex() + ''} style={{ color: '#000' }}>
            {question.question}
          </FormLabel>
          {question.type === 'Open' && (
            <TextField
              name={index + ''}
              variant="outlined"
              fullWidth
              required
              onChange={e => {
                const newAnswer = [...answer];
                newAnswer[index].answer = e.target.value;
                setAnswer(newAnswer);
              }}
              style={{ marginBottom: '16px', maxWidth: '400px' }}
              sx={{
                '& .MuiInputBase-root': {
                  color: azulRemax, // Cambia el color del texto
                  fontWeight: 'bold'
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: azulRemax // Cambia el borde cuando está enfocado
                }
              }}
              value={answer[index].answer}
              type={question.inputType === 'phone' ? 'text' : question.inputType}
            />
          )}
          {question.type === 'Closed' && (
            <>
              <Select
                onChange={e => {
                  const selectedOption = JSON.parse(e.target.value as string) as Options;
                  const newAnswer = [...answer];
                  newAnswer[index].answer = selectedOption.option;
                  newAnswer[index].freeOption = selectedOption.freeOption;
                  setAnswer(newAnswer);
                }}
                name={manager.getIndex() + ''}
                displayEmpty
                style={{ marginBottom: '16px', maxWidth: '400px' }}
                sx={{
                  '& .MuiInputBase-root': {
                    color: azulRemax // Cambia el color del texto
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: azulRemax // Cambia el borde cuando está enfocado
                  }
                }}
              >
                {question.options?.map(m => {
                  return (
                    <MenuItem
                      key={m.option}
                      value={JSON.stringify(m)}
                      selected={answer[index].answer.toLowerCase() === m.option.toLowerCase()}
                    >
                      {m.option}
                    </MenuItem>
                  );
                })}
              </Select>
              {answer[index].freeOption && (
                <TextField
                  name={manager.getIndex() + ''}
                  variant="outlined"
                  fullWidth
                  required
                  onChange={e => {
                    const newAnswer = [...answer];
                    newAnswer[index].freeOptionAnswer = e.target.value;
                    setAnswer(newAnswer);
                  }}
                  style={{ marginBottom: '16px', maxWidth: '400px' }}
                  value={answer[index].freeOptionAnswer}
                  type={'text'}
                />
              )}
            </>
          )}
        </FormControl>

        <Grid item sm={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center', // Espacio uniforme entre botones
              gap: 2 // Espaciado uniforme entre botones
            }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setError(false);
                setIndex(manager.getPrev());
              }}
            >
              Atrás
            </Button>

            <Button
              disabled={manager.isFinish()}
              variant="contained"
              color="primary"
              onClick={e => {
                e.preventDefault();
                if (answer[manager.getIndex()].answer === '') {
                  setError(true);
                } else {
                  setError(false);
                  setIndex(manager.getNext());
                }
              }}
            >
              Continuar
            </Button>

            <Button
              disabled={!manager.isFinish()}
              variant="contained"
              color="primary"
              type="submit"
              onClick={e => {
                if (answer[manager.getIndex()].answer === '') {
                  setError(true);
                } else {
                  setError(false);
                  handleSubmit(e);
                }
              }}
            >
              Enviar
            </Button>
          </Box>
        </Grid>
      </form>
      {/* </Box> */}
    </Container>
  );
};
